<template>
    <div id="forex-heat-map" class="widget-wrapper">
        <div class="tradingview-widget-container">
            <div class="tradingview-widget-container__widget"></div>
        </div>
        <div id="script-container-heat-map" class="widget-script-container"></div>
    </div>
</template>

<script>
const SCRIPT_ID = 'tradingview-widget-script';
const CONTAINER_ID = 'vue-trading-view';

export default {
    name: 'VueTradingView',
    data() {
        return {
            container_id: CONTAINER_ID,
        };
    },
    props: {
        options: Object,
    },
    methods: {
        canUseDOM() {
            return (
                typeof window !== 'undefined' &&
                window.document &&
                window.document.createElement
            );
        },
        getScriptElement() {
            return document.getElementById(SCRIPT_ID);
        },
        updateOnloadListener(onload) {
            const script = this.getScriptElement();
            const oldOnload = script.onload;
            return (script.onload = () => {
                oldOnload();
                onload();
            });
        },
        scriptExists() {
            return this.getScriptElement() !== null;
        },
        appendScript(onload) {
            var text = document.createTextNode(
                '{ "colorTheme": "dark",  "isTransparent": false,  "width": "100%",  "height": "100%",  "locale": "en",  "importanceFilter": "-1,0,1", "currencies" :["EUR", "USD", "JPY",    "GBP",    "CHF",    "AUD"]}'
            );
            const script = document.createElement('script');
            script.id = SCRIPT_ID;
            script.type = 'text/javascript';
            script.appendChild(text);

            script.src =
                'https://s3.tradingview.com/external-embedding/embed-widget-forex-heat-map.js';
            script.async = true;
            script.onload = onload;
            document
                .getElementById('script-container-heat-map')
                .appendChild(script);
        },
    },
    mounted() {
        this.appendScript(this.initWidget);
    },
};
</script>
